/*
 * Created by Dong Nguyen.
 */
// import dotenv from 'dotenv';

import axios from "axios";

let baseURLVAcc;
let baseURLVApp;
let loginPopupPath = '/sign-in';
let forceReturnURL = '';
let { protocol, hostname } = window.location;

/**
 * Biến này được gán trong script file index trong repository vAccount.
 * //TODO: Nên tách riêng vaccount-react và vaccount, đây nên là biến môi trường.
 * @type {unknown}
 */
console.log(process.env.REACT_APP_DOMAIN_MAPPING);
const domainMappings = JSON.parse(process.env.REACT_APP_DOMAIN_MAPPING);
console.debug(domainMappings);

export const isLocal = hostname.includes("local") || hostname.includes("127.0.0.1");

baseURLVAcc = protocol + "//" + hostname;
const domainMapping = domainMappings.find(w => (new RegExp(w.source)).test(hostname));
if (domainMapping) {
    console.debug(domainMapping);
    baseURLVApp = protocol+ "//" + domainMapping.destination.vApp; // URL call api vApp
    baseURLVAcc = domainMapping.destination.vAcc ? (protocol+ "//" + domainMapping.destination.vAcc) : baseURLVAcc; // URL call api vAcc
    loginPopupPath = domainMapping.destination.loginPopupPath || loginPopupPath; // URL login popup url redirect
    forceReturnURL = domainMapping.destination.forceReturnURL
        ? protocol + "//" + domainMapping.destination.forceReturnURL
        : ''; // URL redirect khi login success
} else {
    baseURLVApp = protocol + "//vapp.cotest.abivin.vn"
}
// let url = new window.URL(window.location.href);
// if (url.port && !baseURLVAcc.includes(":" + url.port)) {
//     baseURLVAcc += (":" + url.port).replace("9091", "9090");
// }

// baseURLVAcc = "https://vappaccount.pentest.abivin.vn";

const vAccAxios = axios.create({
    baseURL: baseURLVAcc,
    timeout: 30000,
    withCredentials: true,
});

const vAppAxios = axios.create({
    baseURL: baseURLVApp,
    timeout: 30000,
    withCredentials: true,
});

// vAccAxios.interceptors.request.use(config => {
//     console.log(config);
//     return config;
// }, Promise.reject);

vAccAxios.interceptors.response.use(
    res => {
        if (res.data?.token && res.data?.refreshToken) {
            updateAuthorization(res.data);
        }
        return res;
    },
    error => {
        let originalRequest = error.config;
        if (error.response.status === 401 && originalRequest.url.endsWith("/token")) {
            originalRequest._retry = false;
        } else if (error.response.status === 401 && !originalRequest._retry && error.response.data === "unauthorized!") {
            originalRequest._retry = true;
            return vAccAxios
                .post("/token", {
                    grant_type: "refresh_token",
                })
                .then(({ data }) => {
                    updateAuthorization(data);
                    return axios(originalRequest);
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        }
        return Promise.reject(error);
    }
);

export default vAccAxios;
export const axiosVApp = vAppAxios;
export const vAppURL = baseURLVApp;
export const vAccURL = baseURLVAcc;

export function updateAuthorization({ token, refreshToken }) {
    // localStorage.setItem("refreshToken", refreshToken);
}

export { loginPopupPath, forceReturnURL }
