import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {MENU} from '../../../constants/pricing';
import Button from '@material-ui/core/Button';
import DropDown from "./DropDown";
import { Link } from "react-router-dom"
import {useGlobal} from "../../../actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    marginRight: 30,
    width: 220,
    height: 94,
  },
  title: {
    display: "flex",
    margin: "auto"
  },
  menu: {
    marginRight: 70,
    fontSize: "medium"
  }
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const [globalState, globalActions] = useGlobal();


  return (
    <div className={classes.root} style={{height: 60}}>
      <AppBar position={"fixed"}>
        <Toolbar>
          <div></div>
          <div className={classes.title}>
            <a href={"https://www.abivin.com/"}>
              <img
                  className={classes.logo}
                  src={require("../../../resources/Abivin logo transparent white.png")}
                  alt={"Logo"}
              />
            </a>
            {
              MENU.map((key) => (
                  <DropDown title={key.title} href={key.href} id={key.id} subList={key.subList}/>
              ))
            }
            <Link to={"/sign-up"} style={{ color: "#ffffff", margin: "auto"}}>
              <Button
                  style={{backgroundColor: "orange"}}
                  color="inherit"
                  variant={"contained"}
                  onClick={() => {
                    globalActions.setPlan("Basic")
                  }}
              >
                Try for free
              </Button>
            </Link>
            <a href={"https://www.abivin.com/sign-up"} style={{margin: "auto"}}>
              <Button style={{backgroundColor: "orange", marginLeft: 15, color: "white"}} variant={"contained"}>Contact us</Button>
            </a>
          </div>
          <div></div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
