/*
 * Created by Dong Nguyen.
 */

import React from "react";
import globalHook from "use-global-hook";
import cookies, { getCustomOptions } from "../providers/cookies";
import vAccAxios from "../providers/axios";

const initialState = {
    // user: cookies.get("user"),
    user: null,
    returnURL: cookies.get('return_url'),
    rootOrganizationIds: null,
    isRedirecting: false,
    messageData: null,
    alertData: {},
    plan: null,
};

const actions = {
    setUser: (store, user) => {
        store.setState({ user });
    },
    setPlan: (store, plan) => {
        store.setState({ plan })
    },
    setReturnURL: (store, url) => {
        store.setState({ returnURL: url });
    },
    setRootOrganizationIds: (store, ids) => store.setState({ rootOrganizationIds: ids }),
    setRedirecting: (store, value) => store.setState({ isRedirecting: value }),
    setMessageData: (store, messageData) => store.setState({ messageData }),
    setAlertData: (store, alertData) => store.setState({ alertData }),
    changedPassword: () => {
        let { domain, secure } = getCustomOptions();
        cookies.remove("pwd_warning", {
            secure,
            sameSite: "lax",
            domain,
        });
        cookies.remove("passwordWarnings", {
            secure,
            sameSite: "lax",
            domain,
        });
    },
    logout: store => {
        vAccAxios.post("/auth/signout").catch(console.error);
        let { domain, secure } = getCustomOptions();
        cookies.remove("user");
        cookies.remove("user", {
            secure,
            sameSite: "lax",
            domain,
        });
        cookies.remove("pwd_warning");
        cookies.remove("jwtToken", {
            secure,
            sameSite: "lax",
            domain,
        });
        cookies.remove("passwordWarnings", {
            secure,
            sameSite: "lax",
            domain,
        });
        localStorage.removeItem("refreshToken");
        store.setState({
            user: null,
        });
    },
};

export const useGlobal = globalHook(React, initialState, actions);
