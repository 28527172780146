/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Created by Dong Nguyen.
 */

import * as React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Email, Lock, Person } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import EyeComponent from "../components/EyeComponent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import Grow from "@material-ui/core/Grow";
import axios, { updateAuthorization } from "../providers/axios";
import FormContainerComponent from "../components/FormContainerComponent";
import cookies, { getCustomOptions } from "../providers/cookies";
import { useState } from "react";
import { useGlobal } from "../actions";
import queryString from "query-string";
import getLanguage from "../providers/localize";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import PropTypes from "prop-types";
import IsolateComponent from "../components/IsolateComponent";
import { useLifecycles, useLocalStorage } from "react-use";
import { Fragment } from "react";
import { firebaseAuth } from "../providers/firebaseConfig";

const useStyles = makeStyles(theme => {
    return {
        root: {
            "& .MuiTextField-root": {
                marginBottom: theme.spacing(2),
            },
        },
        labelF14: {
            "& .MuiTypography-body1": {
                fontSize: "16px",
            },
        },
        gridContainer: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(-2),
        },
        registerContainer: {
            marginTop: theme.spacing(4),
            textAlign: "center",
        },
        mUIAlert: {
            marginBottom: theme.spacing(1),
        },
        abix: {
            cursor: "pointer",
        },
    };
});

export default function({ location, history }) {
    const classes = useStyles();
    const [globalState, globalActions] = useGlobal();
    const search = queryString.parse(location.search);

    const [showPassword, setShowPassword] = useState(false);
    const [loginBtnDisabled, setLoginBtnDisabled] = useState(false);
    const [capsLockIsOn, setCapsLockIsOn] = useState(false);
    const [username, setUsername] = useState(null);
    const [, setShadowPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [rememberPassword, setRememberPassword] = useState(localStorage.getItem("rememberPassword") === "true");
    const [openDialog, setOpenDialog] = useState(false);
    const [twoStepData, setTwoStepData] = useState({});
    const [verifying, setVerifying] = useState(false);
    const [verifyCode, setVerifyCode] = useState("");
    const [openResendDialog, setOpenResendDialog] = useState(false);
    const [resendTime, setResendTime] = useState(0);
    const [useAbix, setUseAbix, removeUseAbix] = useLocalStorage("useAbix");

    const _selectOnFocus = e => {
        e.target.select();
    };
    const _onClickLogin = async e => {
        e.preventDefault();
        cookies.remove("passwordWarnings");
        cookies.remove("password_warning");
        cookies.remove("pwd_warning");
        setTwoStepData({});
        setLoginBtnDisabled(true);
        setErrorMessage(null);
        let startAt = Date.now();
        try {
            // Dùng state thì sẽ không nhận token value của abix. Sh*t.
            let password = document.getElementById("password").value;
            let options = {
                lang: getLanguage(),
                username,
                password,
                rootOrganizationIds: globalState.rootOrganizationIds || undefined,
            };
            if (password.length >= 100) {
                options.tokenAutoSignin = password;
            }
            let { data } = await axios.post("/auth/signin", options);
            if (typeof data === "object") {
                if (data.message === "ALREADY_LOGGED_IN_WITH_DIFFERENT_ACCOUNT") {
                    if (globalState.returnURL) {
                        globalActions.setRedirecting(true);
                        window.location.href = globalState.returnURL;
                    }
                } else {
                    let { secure } = getCustomOptions();
                    let expired = new Date();
                    expired.setDate(expired.getDate() + 90);
                    let domain = window.location.hostname
                        .split(".")
                        .slice(-2)
                        .join(".");
                    cookies.set("passwordWarnings", data.pwd_warning, {
                        secure,
                        sameSite: "lax",
                        expires: expired,
                        domain,
                    });
                    updateAuthorization(data);
                    globalActions.setUser(data.user);
                    if (globalState.returnURL) {
                        globalActions.setRedirecting(true);
                        window.location.href = globalState.returnURL;
                    }
                }
            }
        } catch (e) {
            try {
                let {
                    response: { data },
                } = e;
                if (data.two_step_actived) {
                    setOpenDialog(true);
                    setTwoStepData(data);
                } else {
                    await new Promise(rel => setTimeout(rel, 500 - (Date.now() - startAt)));
                    setErrorMessage(data);
                }
            } catch (ignored) {}
        }
        setLoginBtnDisabled(false);
    };
    const _onKeyUp = event => {
        setCapsLockIsOn(event.getModifierState("CapsLock"));
    };
    const _handleCloseDialog = () => {
        setOpenDialog(false);
        setVerifying(false);
        setVerifyCode("");
        setTwoStepData({});
    };
    const _handleVerify = (e, code) => {
        if (e) e.preventDefault();
        setVerifying(true);
        axios
            .post("/auth/two-step-verifier", {
                user_id: twoStepData.user._id,
                token: twoStepData.token,
                code: code || verifyCode,
                lang: getLanguage(),
            })
            .then(({ data }) => {
                setVerifying(false);
                let expired = new Date();
                expired.setDate(expired.getDate() + 30);
                let { domain, secure } = getCustomOptions();
                cookies.set("passwordWarnings", twoStepData.pwd_warning, {
                    secure,
                    sameSite: "lax",
                    expires: expired,
                    domain,
                });
                updateAuthorization(data);
                globalActions.setUser(twoStepData.user);
                if (globalState.returnURL) {
                    globalActions.setRedirecting(true);
                    window.location.href = globalState.returnURL;
                }
            })
            .catch(e => {
                setVerifying(false);
                setVerifyCode("");
                try {
                    let {
                        response: { data },
                    } = e;
                    globalActions.setMessageData({
                        type: "error",
                        content: data.message,
                    });
                } catch (ignored) {}
            });
    };
    const _handleResend = () => {
        setOpenResendDialog(true);
    };

    let shadowPasswordTimer = setInterval(() => {
        try {
            setShadowPassword(document.getElementById("password").value || "");
        } catch (ignored) {}
    }, 50);
    let resendTimer = setInterval(() => {
        let lastResendTime = localStorage.getItem("resendTime");
        if (lastResendTime) {
            lastResendTime = parseInt(lastResendTime);
            setResendTime(parseInt(30 - (Date.now() - lastResendTime) / 1000));
        }
    }, 250);

    useEffect(() => {
        // Mounted.
        if (search.content && search.type) {
            globalActions.setMessageData({
                type: search.type,
                content: search.content,
            });
            history.push("/login");
        }
    }, []);
    useEffect(() => {
        // Mounted or updated.
        return () => {
            // Will unmount.
            clearInterval(shadowPasswordTimer);
            clearInterval(resendTimer);
        };
    });

    useLifecycles(() => {
        if (useAbix === "1") {
            firebaseAuth.onAuthStateChanged(user => {
                if (user) {
                    user = JSON.parse(JSON.stringify(user));
                    if (user.email.endsWith("@abivin.com")) {
                        setUseAbix("1");
                    }
                } else {
                    removeUseAbix();
                }
            });
        }
    });

    return (
        <FormContainerComponent>
            {/*2-step*/}
            <ResendDialog
                onClose={v => {
                    setOpenResendDialog(false);
                    if (resendTime > 0) {
                        globalActions.setMessageData({
                            type: "warning",
                            content: "Wait for " + resendTime + "s",
                        });
                        return;
                    }
                    if (v) {
                        localStorage.setItem("resendTime", Date.now().toString());
                        axios
                            .post("/resend", {
                                lang: getLanguage(),
                                type: v,
                                data: twoStepData.two_step_data,
                            })
                            .then(data => {
                                data = data.data;
                                globalActions.setMessageData({
                                    type: "success",
                                    content: data.message,
                                });
                            })
                            .catch(e => {
                                localStorage.removeItem("resendTime");
                                try {
                                    let {
                                        response: { data },
                                    } = e;
                                    globalActions.setMessageData({
                                        type: "error",
                                        content: data.message,
                                    });
                                } catch (ignored) {}
                            });
                    }
                }}
                open={openResendDialog}
                mobile={twoStepData.user && twoStepData.user.phoneNumber}
                email={twoStepData.user && twoStepData.user.email}
            />
            <Dialog disableBackdropClick disableEscapeKeyDown open={openDialog} onClose={_handleCloseDialog} aria-labelledby="form-dialog-title">
                <form autoComplete="off" onSubmit={_handleVerify}>
                    <DialogTitle id="form-dialog-title">Enter Code</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please copy the verification code that was sent to your email (
                            <IsolateComponent html={twoStepData.user ? twoStepData.user.email : ""} />) and paste below.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="code"
                            label="Verification code"
                            type="number"
                            value={verifyCode}
                            fullWidth={true}
                            required={true}
                            disabled={verifying || (verifyCode && verifyCode.toString().length >= 6)}
                            onChange={e => {
                                setVerifyCode(e.target.value);
                                if (e.target.value.toString().length >= 6) {
                                    _handleVerify(null, e.target.value);
                                }
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={_handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        {/*{twoStepData.valid && (twoStepData.valid.is_email || twoStepData.valid.is_phone) && !verifying && (*/}
                        {/*    <Button onClick={_handleResend} color="primary" disabled={resendTime > 0}>*/}
                        {/*        Resend {resendTime > 0 ? "(" + resendTime + ")" : ""}*/}
                        {/*    </Button>*/}
                        {/*)}*/}
                        <Button type={"submit"} color="primary" disabled={verifying}>
                            Verify{verifying ? "..." : ""}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            {/*end*/}
            <form className={classes.root} autoComplete="off" onSubmit={e => _onClickLogin(e)}>
                <TextField
                    label={"Username or Email"}
                    fullWidth={true}
                    autoFocus={true}
                    required={true}
                    name={"username"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Person />
                            </InputAdornment>
                        ),
                    }}
                    onFocus={v => _selectOnFocus(v)}
                    onChange={e => setUsername(e.target.value)}
                />
                <TextField
                    id={"password"}
                    helperText={capsLockIsOn ? "CAPS LOCK IS ON." : null}
                    label={"Password"}
                    name={"password"}
                    fullWidth={true}
                    required={true}
                    type={showPassword ? "text" : "password"}
                    placeholder={"********"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <Fragment>
                                <EyeComponent onShow={() => setShowPassword(true)} onHide={() => setShowPassword(false)} />
                                {useAbix === "1" && (
                                    <Fragment>
                                        &nbsp;&nbsp;
                                        <img
                                            className={classes.abix}
                                            src={require("../resources/abivin-logo.png")}
                                            width={19}
                                            alt={"abix"}
                                            onClick={() => {
                                                document.body.style.cursor = "wait";
                                                firebaseAuth.currentUser.getIdToken(true).then(token => {
                                                    document.getElementById("password").value = token;
                                                    document.body.style.cursor = "default";
                                                });
                                            }}
                                        />
                                    </Fragment>
                                )}
                            </Fragment>
                        ),
                    }}
                    onFocus={_selectOnFocus}
                    onKeyUp={_onKeyUp}
                    onBlur={() => setCapsLockIsOn(false)}
                />
                <Grid container justify={"center"} className={classes.gridContainer}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                        <FormControlLabel
                            className={classes.labelF14}
                            control={
                                <Checkbox
                                    checked={rememberPassword}
                                    color="primary"
                                    onChange={e => {
                                        localStorage.setItem("rememberPassword", e.target.checked.toString());
                                        setRememberPassword(e.target.checked);
                                    }}
                                />
                            }
                            label={"Remember Password"}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <FormControlLabel
                            className={classes.labelF14}
                            control={<span style={{ display: "inline-block", width: 42, height: 42 }} />}
                            style={{
                                marginRight: 0,
                            }}
                            label={
                                <Link
                                    to={{
                                        pathname: "/forgot-password",
                                        query: { username },
                                    }}
                                    style={{ color: "#2196f3" }}
                                    tabIndex="-1">
                                    {"Forgot Password?"}
                                </Link>
                            }
                        />
                    </Grid>
                </Grid>
                {errorMessage && (
                    <Grow in={!!errorMessage}>
                        <MuiAlert
                            elevation={1}
                            // variant="filled"
                            severity="error"
                            className={classes.mUIAlert}>
                            {errorMessage}
                        </MuiAlert>
                    </Grow>
                )}
                <Button variant="contained" size="large" color="primary" type={"submit"} disabled={loginBtnDisabled} fullWidth={true}>
                    {"SIGN IN"}
                    {loginBtnDisabled ? "..." : ""}
                </Button>
            </form>
            <div className={classes.registerContainer}>
                {"Do not have an account?"}{" "}
                <Link to={"/sign-up"} style={{ color: "#2196f3" }}>
                    {"Sign Up!"}
                </Link>
                {/* <a style={{ color: "#2196f3" }} href="https://www.abivin.com/pricing" target={'_blank'}>Sign Up!</a> */}
            </div>
        </FormContainerComponent>
    );
}

function ResendDialog(props) {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose(null);
    };

    const handleListItemClick = value => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Select</DialogTitle>
            <List>
                {/*{props.mobile && (*/}
                {/*    <ListItem autoFocus button onClick={() => handleListItemClick("mobile")}>*/}
                {/*        <ListItemAvatar>*/}
                {/*            <Avatar>*/}
                {/*                <PhoneIphone />*/}
                {/*            </Avatar>*/}
                {/*        </ListItemAvatar>*/}
                {/*        <ListItemText primary={props.mobile} />*/}
                {/*    </ListItem>*/}
                {/*)}*/}
                {props.email && (
                    <ListItem autoFocus button onClick={() => handleListItemClick("email")}>
                        <ListItemAvatar>
                            <Avatar>
                                <Email />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={props.email} />
                    </ListItem>
                )}
            </List>
        </Dialog>
    );
}

ResendDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    mobile: PropTypes.string,
    email: PropTypes.string,
};
