import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {Link} from "react-router-dom"
import MenuIcon from '@material-ui/icons/Menu';
import SideBarItem from "./SideBar";
import {MENU} from "../../../constants/pricing"
import {useGlobal} from "../../../actions";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
        margin: "auto",
        justifyContent: "space-between"
    },
    logo: {
        width: 35,
        height: 35,
        borderRadius: "50%"
    },
    menu: {
        display: "flex",
        margin: "auto",
        marginRight: 0,
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center"
    },
    buttonCustom: {
      display: "flex",
      margin: "auto",
      justifyContent: "center"
    },
    slideBar: {
        height: "100%",
        width: "40%",
        position: "fixed",
        zIndex: 1,
        top: 0,
        right: 0,
        backgroundColor: "#1b64b0",
        overflowX: "hidden",
        paddingTop: "20px"
    }
}));

const ButtonCustom = styled.button`
  background-color: #f7a100;
  border: none;
  color: #FFFFFF;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 12px;
  font-weight: bold;
`

const MenuIconBorder = styled.button`
  margin-left: 20px;
  color: #FFFFFF;
  background-color: #1b64b0;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 16px;
`
export default function AppBarSmailSize() {
    const classes = useStyles();
    const [globalState, globalActions] = useGlobal();

    const [open, setOpen] = React.useState(false);
    const menuRef = useRef(null);
    const handleOpen = () => {
        setOpen(true);
    }

    useEffect(() => {
        let handle = (e) => {
            if(menuRef.current && !menuRef.current.contains(e.target)) {
                setOpen(false);
            }
        }

        document.addEventListener("mousedown", handle)

        return () => {
            document.removeEventListener("mousedown", handle);
        }
    })

    return (
        <div className={classes.root} style={{height: 60}}>
            <AppBar position={"fixed"}>
                <Toolbar>
                    <div>
                        <a href={"https://www.abivin.com/"}>
                            <img
                                className={classes.logo}
                                src={require("../../../resources/abivin-logo.png")}
                                alt={"Logo"}
                            />
                        </a>
                    </div>
                    <div className={classes.menu}>
                        <a href={"https://www.abivin.com/sign-up"} style={{margin: "auto"}}>
                            <ButtonCustom>Contact Us</ButtonCustom>
                        </a>
                        <Link
                            to={"/sign-up"}
                            onClick={() => {
                                globalActions.setPlan("Basic")
                            }}
                        >
                            <ButtonCustom>
                                Try For Free
                            </ButtonCustom>
                        </Link>
                        <MenuIconBorder>
                            <MenuIcon onClick={handleOpen}/>
                        </MenuIconBorder>
                    </div>
                    {open ?
                        <div className={classes.slideBar} ref={menuRef}>
                            {MENU.map((value) => (
                                <SideBarItem href={value.href} title={value.title} subList={value.subList}/>
                            ))}
                        </div>
                        : ""
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
}
