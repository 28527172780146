/*
 * Created by Dong Nguyen.
 */

export function getCurrentLanguage() {
    // eslint-disable-next-line no-undef
    let lang = Localize.getLanguage() || "";
    if (lang.includes("-")) {
        // en-US
        lang = lang.split("-")[0];
    }
    return lang;
}

export function setCurrentLanguage(lang) {
    // eslint-disable-next-line no-undef
    Localize.setLanguage(lang);
}

export default function getLanguage() {
    let lang = getCurrentLanguage();
    if (!["en", "vi"].includes(lang)) lang = "en"; // vAccount backend 12/02/2020 chỉ hỗ trợ 2 ngôn ngữ.
    return lang;
}
