/*
 * Created by Dong Nguyen.
 */

import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormContainerComponent from "../components/FormContainerComponent";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Lock, Person } from "@material-ui/icons";
import BusinessIcon from '@material-ui/icons/Business';
import PhoneIcon from '@material-ui/icons/Phone';
import EyeComponent from "../components/EyeComponent";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import validator from "validator";
import Popper from "@material-ui/core/Popper";
import StrongPasswordComponent from "../components/StrongPasswordComponent";
import { isStrongPassword } from "../providers/strongPassword";
import Grow from "@material-ui/core/Grow";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import axios from "../providers/axios";
import { useGlobal } from "../actions";
import VerifyPasswordComponent from "../components/VerifyPasswordComponent";
import getLanguage from "../providers/localize";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash'
import { Packages } from '../constants/pricing'

const useStyles = makeStyles(theme => {
    return {
        sample: {
            margin: theme.spacing(1),
        },
        root: {
            "& .MuiTextField-root": {
                marginBottom: theme.spacing(2),
            },
        },
        telInput: {
            display: "none",
        },
        registerButton: {
            marginTop: theme.spacing(1),
        },
        loginContainer: {
            marginTop: theme.spacing(4),
            textAlign: "center",
        },
    };
});

export default function({ history }) {
    const classes = useStyles();
    const [globalState, globalActions] = useGlobal();
    const matches = useMediaQuery('(min-width:800px)');

    const [capsLockIsOn, setCapsLockIsOn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = useState(false);
    const [username, setUsername] = useState(null);
    const [name, setName] = useState(null);
    const [password, setPassword] = useState(null);
    const [email, setEmail] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [verifyPassword, setVerifyPassword] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElVerify, setAnchorElVerify] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorVerifyPassword, setErrorVerifyPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [plan, setPlan] = useState(globalState.plan)

    const _onSubmit = e => {
        e.preventDefault();
        setAnchorEl(null);
        setErrorMessage(null);
        setErrorVerifyPassword(false);
        if (!isStrongPassword(password)) {
            setErrorMessage("Password is too weak!");
            return;
        }
        if (password !== verifyPassword) {
            setErrorVerifyPassword(true);
            return;
        }
        setLoading(true);
        axios
            .post("/auth/signup", {
                email,
                username,
                hostname: window.location.origin || window.location.hostname,
                lang: getLanguage(),
                password,
                companyName,
                name,
                phoneNumber,
                source: "wix",
                package: plan,
                isActivated: true,
                mail: 'yes',
            })
            .then(({ data }) => {
                setLoading(false);
                let msg;
                if (data && data.success) {
                    msg = "Signup successfully! Please wait until our administrator activate your account.";
                } else {
                    msg = "We have sent a confirmation email to <var isolate>#email#</var>. Please check the mailbox!".replace("#email#", data.user.email);
                }
                globalActions.setAlertData({
                    title: "Signup successfully!",
                    content: msg,
                    onClose() {
                        history.push("/login");
                    },
                });
            })
            .catch(e => {
                setLoading(false);
                try {
                    let {
                        response: { data },
                    } = e;
                    if (data.message) {
                        setErrorMessage(data.message);
                    }
                } catch (ignored) {}
            });
    };
    const _selectOnFocus = (e, ignore, ignore2) => {
        e.target.select();
        if (ignore !== true) {
            setAnchorEl(null);
        }
        if (ignore2 !== true) {
            setAnchorElVerify(null);
        }
    };
    const _onKeyUp = event => {
        setCapsLockIsOn(event.getModifierState("CapsLock"));
    };

    return (
        <FormContainerComponent>
            <form className={classes.root} autoComplete="off" onSubmit={e => _onSubmit(e)}>
                <TextField
                    fullWidth={true}
                    required={true}
                    label={"Email"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Person />
                            </InputAdornment>
                        ),
                    }}
                    onFocus={_selectOnFocus}
                    onChange={e => {
                        setEmail(e.target.value)
                        setUsername(e.target.value)
                    }}
                    autoFocus={true}
                />
                <TextField
                    fullWidth={true}
                    required={true}
                    type={"text"}
                    label={"Your Full Name"}
                    placeholder={"Nguyen Van A"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <BusinessIcon />
                            </InputAdornment>
                        ),
                    }}
                    onFocus={_selectOnFocus}
                    onChange={e => setName(e.target.value)}
                />
                <TextField
                    fullWidth={true}
                    required={false}
                    type={"text"}
                    label={"Your Company Name"}
                    placeholder={"Abivin"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <BusinessIcon />
                            </InputAdornment>
                        ),
                    }}
                    onFocus={_selectOnFocus}
                    onChange={e => setCompanyName(e.target.value)}
                />
                <TextField
                    fullWidth={true}
                    required={true}
                    type={"number"}
                    label={"Phone Number"}
                    placeholder={"0988668668"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PhoneIcon />
                            </InputAdornment>
                        ),
                    }}
                    onFocus={_selectOnFocus}
                    onChange={e => setPhoneNumber(e.target.value)}
                />
                <TextField
                    helperText={capsLockIsOn ? "CAPS LOCK IS ON." : null}
                    label={"Password"}
                    fullWidth={true}
                    required={true}
                    type={showPassword ? "text" : "password"}
                    placeholder={"********"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock />
                            </InputAdornment>
                        ),
                        endAdornment: <EyeComponent onShow={() => setShowPassword(true)} onHide={() => setShowPassword(false)} />,
                    }}
                    onFocus={e => {
                        setAnchorEl(e.currentTarget);
                        _selectOnFocus(e, true);
                    }}
                    onKeyUp={_onKeyUp}
                    onBlur={() => {
                        // setAnchorEl(null);
                        setCapsLockIsOn(false);
                    }}
                    onChange={e => setPassword(e.target.value)}
                />
                <Popper
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    placement={matches ? 'right' : 'bottom'}
                    style={{
                        pointerEvents: "none",
                    }}>
                    <StrongPasswordComponent value={password} />
                </Popper>
                <TextField
                    error={errorVerifyPassword}
                    label={"Verify Password"}
                    fullWidth={true}
                    required={true}
                    type={showVerifyPassword ? "text" : "password"}
                    placeholder={"********"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock />
                            </InputAdornment>
                        ),
                        endAdornment: <EyeComponent onShow={() => setShowVerifyPassword(true)} onHide={() => setShowVerifyPassword(false)} />,
                    }}
                    onFocus={e => {
                        setAnchorElVerify(e.currentTarget);
                        _selectOnFocus(e, false, true);
                    }}
                    onBlur={() => {
                        setAnchorElVerify(null);
                    }}
                    onChange={e => {
                        setVerifyPassword(e.target.value);
                        setErrorVerifyPassword(false);
                    }}
                />
                <TextField
                    id="standard-select-currency"
                    select
                    label="Select plan"
                    fullWidth
                    required
                    value={plan}
                    onChange={(e) => setPlan(e.target.value)}
                    style={{textAlign: 'left'}}
                    >
                    {Packages.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                <Popper
                    open={!!anchorElVerify}
                    anchorEl={anchorElVerify}
                    placement={matches ? 'right' : 'bottom'}
                    style={{
                        pointerEvents: "none",
                    }}>
                    <VerifyPasswordComponent value={password && password === verifyPassword} message={"Must be the same password."} />
                </Popper>
                {errorMessage && (
                    <Grow in={!!errorMessage}>
                        <MuiAlert elevation={1} severity="error" className={classes.mUIAlert}>
                            {errorMessage}
                        </MuiAlert>
                    </Grow>
                )}
                <Button disabled={loading} className={classes.registerButton} variant="contained" size="large" color="primary" type={"submit"} fullWidth={true}>
                    {"SIGN UP"}
                    {loading ? "..." : ""}
                </Button>
            </form>
            <div className={classes.loginContainer}>
                {"Already have an account?"}{" "}
                <Link to={"/sign-in"} style={{ color: "#2196f3" }}>
                    {"Sign In!"}
                </Link>
            </div>
        </FormContainerComponent>
    );
}
