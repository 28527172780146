/*
 * Created by Dong Nguyen.
 */

/* eslint-disable react-hooks/exhaustive-deps */

import * as React from "react";
import { Paper } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useEffect } from "react";
import { axiosVApp, vAppURL } from "../providers/axios";
import { useGlobal } from "../actions";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { AccountCircle } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => {
    return {
        root: {
            "& .MuiTextField-root": {
                marginBottom: theme.spacing(2),
            },
        },
        formContainer: {
            width: "40%",
            maxWidth: 600,
            minWidth: 100,
            paddingLeft: theme.spacing(12),
            paddingRight: theme.spacing(12),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            display: "inline-block",
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(8),
        },
        logo: {
            width: 260,
            height: "auto",
            marginBottom: theme.spacing(4),
        },
        power: {
            width: "100%",
            position: "absolute",
            bottom: theme.spacing(2),
            left: 0,
            textAlign: "center",
        },
    };
});

export default function({ children }) {
    const classes = useStyles();
    const { hostname } = window.location;
    const [globalState, globalActions] = useGlobal();
    // Khi logo có thay đổi thì cần thay mã hash này thành 1 hash khác bất kỳ
    const logoStoreHash = "7cf4752d-fb08-4bd0-aaa2-637bc3f9b593";
    const logoStoreKey = [hostname, "logo", logoStoreHash].join();

    const [logo, setLogo] = React.useState(localStorage.getItem(logoStoreKey) || require("../resources/main-logo-gray.png"));
    const [blueRectangle, setBlueRectangle] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);

    let formContainerRef = null;

    const _fixBlueRectangle = () => {
        if (formContainerRef) {
            setBlueRectangle(parseInt(formContainerRef.offsetTop + formContainerRef.offsetHeight / 3));
        }
    };

    useEffect(() => {
        // Mounted.
        _fixBlueRectangle();
        // Lấy logo
        let defaultLogo = require("../resources/main-logo.png");
        axiosVApp
            .post("/domain-mapping/read", {
                loginDomain: hostname,
            })
            .then(({ data }) => {
                if (typeof data === "object" && data !== null) {
                    if (data.vaccLogoUrl) {
                        setLogo(data.vaccLogoUrl);
                        localStorage.setItem(logoStoreKey, data.vaccLogoUrl);
                    }
                    if (data.rootOrganizationIds) {
                        globalActions.setRootOrganizationIds(data.rootOrganizationIds);
                    }
                    if (data.vAppPageTitle) {
                        document.title = data.vAppPageTitle;
                    }
                    if (data.faviconUrl) {
                        document.querySelector("link[rel*='icon']").href = data.faviconUrl;
                    }
                    if (data.vAppForwardUrl) {
                        globalActions.setReturnURL(data.vAppForwardUrl);
                    }
                } else {
                    setLogo(defaultLogo);
                    localStorage.setItem(logoStoreKey, defaultLogo);
                }
            })
            .catch(() => {
                setLogo(defaultLogo);
                localStorage.setItem(logoStoreKey, defaultLogo);
            });
    }, []);

    useEffect(() => {
        // Mounted or updated.
        function onWindowResize() {
            _fixBlueRectangle();
        }
        window.addEventListener("resize", onWindowResize);
        return () => {
            // Will unmount.
            window.removeEventListener("resize", onWindowResize);
        };
    });

    return (
        <div
            style={{
                display: "table",
                width: "100%",
                height: "100vh",
                position: "relative",
                overflow: "auto",
            }}>
            {globalState.user && !globalState.isRedirecting && (
                <AppBar position="fixed" color={"inherit"} className={"animated fadeInDown"}>
                    <Toolbar variant="dense">
                        <img
                            src={logo}
                            style={{
                                height: 28,
                                cursor: "pointer",
                            }}
                            alt={"logo"}
                            onClick={() => {
                                window.location.href = vAppURL;
                            }}
                        />
                        <div
                            style={{
                                width: "100%",
                                textAlign: "right",
                            }}>
                            <IconButton
                                title={globalState.user.email}
                                onClick={e => {
                                    setAnchorEl(e.currentTarget);
                                }}
                                color="inherit">
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={!!anchorEl}
                                onClose={() => {
                                    setAnchorEl(null);
                                }}>
                                <MenuItem key="placeholder" style={{ display: "none" }} />
                                <MenuItem
                                    button={false}
                                    style={{
                                        border: 0,
                                    }}>
                                    <Typography color="textSecondary" display="block" variant="caption">
                                        {globalState.user.email}
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setAnchorEl(null);
                                        // globalActions.logout();
                                        window.location.href = "/#/logout";
                                    }}>
                                    {"Logout"}
                                </MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
            )}
            <div
                style={{
                    height: blueRectangle,
                    width: "100%",
                    position: "absolute",
                    background: "#1b64b0",
                    zIndex: -1,
                }}
            />
            <div
                style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                }}>
                <div className={classes.power}>{"Powered by Abivin"}</div>
            </div>
            <div
                style={{
                    display: "table-cell",
                    width: "100%",
                    height: "100vh",
                    textAlign: "center",
                    verticalAlign: "middle",
                }}>
                <Paper elevation={3} className={classes.formContainer} ref={ref => (formContainerRef = ref)}>
                    <div style={{ textAlign: "center" }}>
                        {/*<Tooltip title={"go to " + vAppURL}>*/}
                        <img
                            onClick={() => (window.location.href = vAppURL)}
                            src={logo}
                            alt={"Logo"}
                            className={[classes.logo].join(" ")}
                            style={window.screen.width < 670 ? { width: 180, cursor: "pointer" } : { cursor: "pointer" }}
                            title={vAppURL}
                        />
                        {/*</Tooltip>*/}
                    </div>
                    {children}
                </Paper>
            </div>
        </div>
    );
}
