import React from 'react';
import styled from "styled-components";



const DropDownContent = styled.div`
  display: none;
  position: absolute;
  margin-top: 20px;
  margin-right: 20px;
  background-color: #1b64b0;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
`

const DropDownContentItem = styled.a`
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  &:hover {
    color: #ffa500;
  }
`
const Title = styled.span`
  color: #FFFFFF;
  margin-top: 35px;
  margin-right: 90px;
  &:hover {
    color: #ffa500;
  }
`

const DropDownCom = styled.div`
  display: flex;
  margin: auto;
  &:hover ${DropDownContent} {
    display: block;
  }
`
export default function DropDown(props) {

    return (
        <DropDownCom>
            <a href={props.href ? props.href : ""}>
                <Title>
                    {props.title}
                </Title>
            </a>
            <DropDownContent>
                {
                    props.subList ? props.subList.map((value) => (
                        <a href={value.href}>
                            <DropDownContentItem>
                                {value.title}
                            </DropDownContentItem>
                        </a>
                    )) : <div></div>
                }
            </DropDownContent>
        </DropDownCom>
    )
}