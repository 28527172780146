/*
 * Created by Dong Nguyen.
 */

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import { LoginInternal } from "./pages/LoginPage.internal";
import { LoginExternal } from "./pages/LoginPage.external";
import { LoginSuccess } from "./pages/LoginSuccess";
import { LoginError } from "./pages/LoginError";
import { useGlobal } from "./actions";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import ChangePassword from "./pages/ChangePasswordPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ChangePasswordByTokenPage from "./pages/ChangePasswordByTokenPage";
import PricingPlan from "./pages/PricingPlan";
import axios, { forceReturnURL, loginPopupPath, vAppURL } from "./providers/axios";

import "./styles/common.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import cookies, { getCustomOptions } from "./providers/cookies";
import { getCurrentLanguage, setCurrentLanguage } from "./providers/localize";
import { useLifecycles } from "react-use";
import AbixPage from "./pages/AbixPage";
import vAccAxios from "./providers/axios";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#1b64b0",
        },
    },
});

export default function App() {
    const [globalState, globalActions] = useGlobal();
    const [loading, setLoading] = useState(false);

    useLifecycles(() => {
        if (globalState.user) {
            setLoading(false);
            return;
        }
        // axios
        //     .post("/users/info")
        //     .then(v => {
        //         globalActions.setUser(v.data);
        //         setLoading(false);
        //     })
        //     .catch((e) => {
        //         setLoading(false)
        //         console.log('e////', e)
        //     });
    });

    return loading ? null : <BigMamaPage />;
}

function BigMamaPage() {
    const [globalState, globalActions] = useGlobal();
    const isLogged = globalState.user;

    let loggedPath = null;
    if (isLogged) {
        loggedPath = "/u/" + globalState.user.username;
    }

    const _handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        globalActions.setMessageData(null);
    };

    const _handleAlertClose = () => {
        globalState.alertData.onClose && globalState.alertData.onClose();
        globalActions.setAlertData({});
    };

    let lastCheckLang;
    let selectedLang = cookies.get("selectedLanguage");
    if (selectedLang) {
        setCurrentLanguage(selectedLang);
    }
    let localizeLangChange = setInterval(() => {
        let currentLang = getCurrentLanguage();
        if (lastCheckLang !== currentLang) {
            let { domain, secure } = getCustomOptions();
            cookies.set("selectedLanguage", currentLang, {
                secure,
                sameSite: "lax",
                domain,
            });
            lastCheckLang = currentLang;
        }
    }, 33); // 30 FPS

    useEffect(() => {
        return () => {
            // Will unmount.
            clearInterval(localizeLangChange);
        };
    }, [localizeLangChange]);


    return (
        <ThemeProvider theme={theme}>
            {!!globalState.messageData && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={!!globalState.messageData}
                    autoHideDuration={6000}
                    onClose={_handleClose}>
                    <Alert elevation={6} variant="filled" onClose={_handleClose} severity={globalState.messageData.type}>
                        <span
                            style={{
                                textTransform: "capitalize",
                            }}>
                            {globalState.messageData.content}
                        </span>
                    </Alert>
                </Snackbar>
            )}
            <Dialog
                open={!!globalState.alertData.content}
                onClose={_handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{globalState.alertData.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        dangerouslySetInnerHTML={{
                            __html: globalState.alertData.content,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={_handleAlertClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Router>
                {isLogged && !globalState.isRedirecting ? (
                    <Switch>
                        <Route exact path="/login/success" component={LoginSuccess} />
                        <Route exact path="/login/error" component={LoginError} />
                        <Route path={loggedPath} />
                        <Route path={"/change-password"} component={ChangePassword} />
                        <Route
                            path={"/logout"}
                            component={() => {
                                vAccAxios.post("/auth/signout").then(() => globalActions.logout());
                                return null;
                            }}
                        />
                        <Route
                            path={"*"}
                            component={() => {
                                console.debug('Redirect main page: ' + globalState.returnURL);
                                console.debug(globalState);
                                window.location.href = forceReturnURL || globalState.returnURL || vAppURL;
                                return null;
                            }}
                        />
                    </Switch>
                ) : (
                    <Switch>
                        <Route exact path="/login/success" component={LoginSuccess} />
                        <Route exact path="/login/error" component={LoginError} />
                        <Route path="/login/internal" component={LoginInternal} />
                        <Route path="/login/external" component={LoginExternal} />
                        <Route path="/sign-in" component={LoginPage} />
                        <Route path="/sign-up" component={RegisterPage} />
                        <Route path={"/forgot-password"} component={ForgotPasswordPage} />
                        <Route path="/welcome">Welcome!!!!!!!!</Route>
                        <Route path={"/password/reset/:token"} component={ChangePasswordByTokenPage} />
                        <Route path={"/abix"} component={AbixPage} />
                        <Route path={"/pricing"} component={PricingPlan} />
                        <Route path="*">
                            <Redirect to={loginPopupPath} />
                        </Route>
                    </Switch>
                )}
            </Router>
        </ThemeProvider>
    );
}
