/*
 * Created by Dong Nguyen.
 */

/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Created by Dong Nguyen.
 */

import React, { useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ListItemIcon, ListItemText, Paper } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { CheckCircle } from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import { checkers } from "../providers/strongPassword";

const useStyles = makeStyles(theme => {
    return {
        sample: {
            margin: theme.spacing(1),
        },
        container: {
            marginLeft: 40,
            pointerEvents: "auto",
        },
        yes: {
            color: "rgb(83, 183, 106)",
        },
        no: {
            color: "rgb(215, 215, 215)",
        },
        fontSize: {
            fontSize: "16px",
        },
    };
});

export default function({ value }) {
    const classes = useStyles();

    const data = [
        "Contains uppercase letters.",
        "Contains lowercase letters.",
        "Contains numbers.",
        "Contains special characters.",
        "Is at least 8 characters long.",
    ];

    useEffect(() => {
        // Mounted.
    }, []);
    useEffect(() => {
        // Mounted or updated.
    });
    useEffect(() => {
        return () => {
            // Will unmount.
        };
    }, []);

    return (
        <Paper elevation={2} className={classes.container}>
            <List>
                {data.map((v, i) => {
                    let currentClass = classes.no;
                    if (checkers[i](value)) currentClass = classes.yes;
                    return (
                        <ListItem key={["l", i].join()} button={true}>
                            <ListItemIcon>
                                <CheckCircle className={currentClass} />
                            </ListItemIcon>
                            <ListItemText primary={<span className={classes.fontSize}>{v}</span>} />
                        </ListItem>
                    );
                })}
                <Divider component="li" />
                <ListItem
                    button={true}
                    onClick={() => {
                        window
                            .open("https://docs.abivin.com/docs/web-app-account#section-password-rules", "_blank")
                            .focus();
                    }}>
                    <ListItemText
                        primary={
                            <span style={{ color: "rgb(62, 164, 245)" }} className={classes.fontSize}>
                                {"How to create a strong password?"}
                            </span>
                        }
                    />
                </ListItem>
            </List>
        </Paper>
    );
}
