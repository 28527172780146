/*
 * Created by Dong Nguyen.
 */
import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCJGuxTFcsgmovcSRr8OVTKkwpwrXNmc9I",
    authDomain: "abix-signin.abivin.vn",
    databaseURL: "https://auth-2738f.firebaseio.com",
    projectId: "auth-2738f",
    storageBucket: "auth-2738f.appspot.com",
    messagingSenderId: "745154524630",
    appId: "1:745154524630:web:c25c00055ab2dc3dc62077",
};

firebase.initializeApp(firebaseConfig);

export const firebaseAuth = firebase.auth();
