/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Created by Dong Nguyen.
 */

import React, { Fragment, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useCopyToClipboard, useLifecycles, useLocalStorage } from "react-use";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import firebase from "firebase/app";
import { firebaseAuth } from "../providers/firebaseConfig";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles(theme => {
    return {
        root: {
            background: "white",
            padding: theme.spacing(2),
            margin: "auto",
            maxWidth: 300,
            // minHeight: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            marginTop: theme.spacing(6),
        },
        space: {
            marginTop: theme.spacing(2),
        },
        bottom: {
            position: "absolute",
            left: 0,
            top: -40,
        },
    };
});

export default function({ history }) {
    let elementById = document.getElementById("css-root");
    if (elementById) elementById.remove();
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [, setUseAbix, removeUseAbix] = useLocalStorage("useAbix");
    const [state, copyToClipboard] = useCopyToClipboard();

    useLifecycles(() => {
        setLoading(true);
        firebaseAuth.onAuthStateChanged(user => {
            if (user) {
                user = JSON.parse(JSON.stringify(user));
                console.log(user);
                if (user.email.endsWith("@abivin.com")) {
                    setUseAbix("1");
                    setUser(user);
                } else {
                    alert("You don't have permission!");
                    logout(true);
                }
            } else {
                setUser(null);
                removeUseAbix();
            }
            setLoading(false);
        });
    });

    const getToken = () => {
        setLoading(true);
        let provider = new firebase.auth.GoogleAuthProvider();
        firebaseAuth
            .signInWithRedirect(provider)
            .then(() => {
                setLoading(false);
            })
            .catch(e => {
                alert(e.toString());
                setLoading(false);
            });
    };

    const logout = force => {
        if (force || window.confirm("Are you sure you want to log out?")) {
            setLoading(true);
            firebaseAuth
                .signOut()
                .then(() => {
                    removeUseAbix();
                    setLoading(false);
                })
                .catch(e => {
                    alert(e.toString());
                    setLoading(false);
                });
        }
    };

    return (
        <Fragment>
            <Box boxShadow={2} borderRadius={3} className={classes.root}>
                <div hidden={!loading}>
                    <Typography>Processing...</Typography>
                    <LinearProgress />
                </div>
                <div hidden={loading}>
                    {!user && (
                        <Button color={"primary"} variant={"contained"} onClick={getToken}>
                            Login
                        </Button>
                    )}
                    {user && (
                        <Fragment>
                            <TextField
                                label={user.email}
                                variant={"outlined"}
                                value={user.stsTokenManager.accessToken}
                                fullWidth={true}
                                onClick={e => e.target.select()}
                            />
                            <Button
                                onClick={() => copyToClipboard(user.stsTokenManager.accessToken)}
                                fullWidth={true}
                                variant={"contained"}
                                className={classes.space}
                                color={"primary"}>
                                {state.value ? "Copied" : "Copy To Clipboard"}
                            </Button>
                            <Button onClick={logout} fullWidth={true} variant={"outlined"} className={classes.space}>
                                Logout
                            </Button>
                        </Fragment>
                    )}
                </div>
                <div className={classes.bottom}>
                    <Button onClick={() => history.push("/login")} variant={"outlined"}>
                        <ArrowBack className={classes.backIcon} />
                        &nbsp;&nbsp;SIGN IN
                    </Button>
                </div>
            </Box>
        </Fragment>
    );
}
