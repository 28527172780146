import React, { useEffect } from "react";
import axios from "../providers/axios";
import { useGlobal } from "../actions";

export function LoginExternal({ location, history }) {
    const [globalState, globalActions] = useGlobal();

    const fetchAuthUser = async () => {
        try {
            const currentUserUrl = "/saml/current-user";
            const foundUser = await axios.get(currentUserUrl, { withCredentials: true });

            if (!foundUser.data.user) {
                throw new Error("Not found current user!");
            }
            globalActions.setUser(foundUser.data.user);
            console.debug("fetchAuthUser: ", foundUser);
            history.push("/login/success");
        } catch (err) {
            console.error(err);
            const errMessage = err.response.data.message ? err.response.data.message : "Something went wrong!";
            history.push({
                pathname: "/login/error",
                state: { errorMessage: errMessage },
            });
        }
    };

    const login = async e => {
        let timer = null;
        const loginLink = "/saml/login";
        const newWindow = window.open(loginLink);

        if (newWindow) {
            timer = setInterval(() => {
                if (newWindow.closed) {
                    fetchAuthUser();
                    console.debug("Login third party success");
                    if (timer) clearInterval(timer);
                }
            }, 1000);
        }
    };

    useEffect(() => {
        const loginNow = async () => {
            await login();
        };
        loginNow();
    }, []);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
            Waiting for external user authentication ...
        </div>
    );
}
