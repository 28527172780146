/*
 * Created by Dong Nguyen.
 */

/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Created by Dong Nguyen.
 */

import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormContainerComponent from "../components/FormContainerComponent";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ArrowBack, Person } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import axios, { vAccURL } from "../providers/axios";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import getLanguage from "../providers/localize";

const useStyles = makeStyles(theme => {
    return {
        sample: {
            margin: theme.spacing(1),
        },
        root: {
            "& .MuiTextField-root": {
                marginBottom: theme.spacing(2),
            },
        },
        desc: {
            color: "rgb(172, 176, 178)",
            marginBottom: theme.spacing(2),
            textJustify: "inter-word",
        },
        submitButton: {
            marginTop: theme.spacing(1),
        },
        backIcon: {
            marginRight: theme.spacing(1),
        },
        backButton: {
            marginTop: theme.spacing(1),
        },
    };
});

export default function({ history, location }) {
    const classes = useStyles();

    const [email, setEmail] = useState(location.query ? location.query.username : null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(null);
    const [r, setR] = useState(null);

    const _onSubmit = e => {
        if (e) e.preventDefault();
        setErrorMessage(null);
        setLoading(true);
        axios
            .post("/auth/forgot", {
                lang: getLanguage(),
                username: email,
                host: vAccURL,
            })
            .then(({ data }) => {
                setOpen(true);
                setLoading(false);
                if (data.message) {
                    setMessage(data.message);
                }
                if (Number.isInteger(data.r)) setR(data.r);
                else setR(null);
            })
            .catch(e => {
                setLoading(false);
                try {
                    let {
                        response: { data },
                    } = e;
                    setErrorMessage(data.message);
                } catch (ignored) {}
            });
    };
    const _selectOnFocus = e => {
        e.target.select();
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        // Mounted.
    }, []);
    useEffect(() => {
        // Mounted or updated.
    });
    useEffect(() => {
        return () => {
            // Will unmount.
        };
    }, []);

    return (
        <FormContainerComponent>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{"Recover Password"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {message ? message : null}
                        {Number.isInteger(r) && (
                            <div
                                style={{
                                    color: "#ff4444",
                                }}>
                                {"Link has been sent!"} {(parseInt(r / 1000) || 1) + "s"}
                            </div>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            _onSubmit();
                        }}
                        disabled={loading}
                        color="primary">
                        {"Send me another link"}
                        {loading ? "..." : ""}
                    </Button>
                    <Button onClick={handleClose} variant="contained" color="primary" autoFocus>
                        {"Understood"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Typography variant="h4">{"Forgot Password"}</Typography>
            <p className={classes.desc}>
                {
                    'To reset password for your account, please input your Username below then click on "Email me a recovery link".'
                }{" "}
                {"An instruction email will be sent to you immediately."}
            </p>
            <form
                className={classes.root}
                autoComplete="off"
                onSubmit={e => _onSubmit(e)}
                style={{
                    textAlign: "left",
                }}>
                <TextField
                    label={"Username"}
                    type={"text"}
                    value={email}
                    fullWidth={true}
                    autoFocus={true}
                    required={true}
                    name={"username"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Person />
                            </InputAdornment>
                        ),
                    }}
                    placeholder={"Your username"}
                    onFocus={v => _selectOnFocus(v)}
                    onChange={e => setEmail(e.target.value)}
                />
                {errorMessage && (
                    <Grow in={!!errorMessage}>
                        <MuiAlert
                            elevation={1}
                            // variant="filled"
                            severity="error"
                            className={classes.mUIAlert}>
                            {errorMessage}
                        </MuiAlert>
                    </Grow>
                )}
                <Button
                    disabled={loading && !open}
                    className={classes.submitButton}
                    variant="contained"
                    size="large"
                    color="primary"
                    type={"submit"}
                    fullWidth={true}>
                    {"EMAIL ME A RECOVERY LINK"}
                    {loading && !open ? "..." : ""}
                </Button>
            </form>
            <div
                style={{
                    textAlign: "left",
                }}>
                <Button
                    color="primary"
                    className={classes.backButton}
                    onClick={() => {
                        history.push("/login");
                    }}>
                    <ArrowBack className={classes.backIcon} />
                    {"BACK TO SIGN IN"}
                </Button>
            </div>
        </FormContainerComponent>
    );
}
