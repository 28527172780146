/*
 * Created by Dong Nguyen.
 */

import React from "react";

function IsolateComponent(props) {
    return (
        <span
            dangerouslySetInnerHTML={{
                __html: "<var isolate>" + props.html + "</var>",
            }}
        />
    );
}

export default IsolateComponent;
