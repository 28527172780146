import React from "react";
import styled from "styled-components";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const Root = styled.div`
  display: block;
  max-width: 100% ;
`

const Button = styled.button`
  width: 83%;
  display: flex;
  justify-content: space-between;
  color: black;
  background-color: #FFFFFF;
  border: none;
  outline: none;
  text-align: left;
  
`
const Title = styled.span`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 400;
  padding-left: 20%;
  
`

const Content = styled.div`
  alignment: right;
  overflow: hidden;
  display: block;
  max-width: 66%;
  margin: auto;
`

const Icon = styled.span`
  alignment: left;
`

export default function AskedQuestion (props) {
    const [open, setOpen] = React.useState(false);
    return (
        <Root>
            <Button onClick={() => setOpen(!open)}>
                <Title>
                    {props.ask}
                </Title>
                <Icon>
                    {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </Icon>
            </Button>
            {
                open ?
                    <Content>
                        <p>
                            {props.answer}{props.href ? <a href={props.href}> here</a> : ""}
                        </p>
                    </Content>
                    : <div></div>
            }
            <hr width={"66%"} align={"center"} style={{marginBottom: 40}}/>
        </Root>
    )
}