import React, { useEffect } from "react";

export function LoginSuccess() {
    const waitTime = process.env.REACT_APP_DEPLOY_ENV === "prod" ? 500 : 2000;
    useEffect(() => {
        setTimeout(() => {
            window.close();
        }, waitTime);
    }, []);
    console.debug("Login success");
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
            Logged in success! Redirecting you to Abivin vRoute ...
        </div>
    );
}
