import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styled from "styled-components";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    cellHead: {
        color: "#1b64b0",
        fontWeight: 500,
        lineHeight: "1.5rem",
        display: "table-cell",
        padding: "16px",
        fontSize: "0.875rem",
        textAlign: "left",
        fontFamily:"R"
    }
});

const CellHead = styled.th`
  color: #1b64b0;
  font-weight: 500;
  line-height: 1.5rem;
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: ${props => props.align || "left"};
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: center;
  width: ${props => props.width}
`

const DoneBorder = styled.span`
  height: 25px;
  width: 25px;
  background-color: #1b64b0;
  border-radius: 50%;
  display: inline-block;
`

const RemoveBorder = styled.span`
  height: 25px;
  width: 25px;
  background-color: #be4d25;
  border-radius: 50%;
  display: inline-block;
`

const renderItem = (value) => {
    if (typeof (value) === "boolean") {
        return value ? <DoneBorder><DoneRoundedIcon style={{color: "#ffffff"}}/></DoneBorder> : <RemoveBorder><RemoveRoundedIcon style={{color: "#ffffff"}}/></RemoveBorder>
    } else {
        return (<div style={{color: "#1b64b0", fontWeight: 600}}>{value}</div>);
    }
};

export default function ComparisonTable(props) {
    const classes = useStyles();

    return (
        <TableContainer component={Paper} style={{marginBottom:30}}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead style={{height: 100, fontWeight: 600, fontSize: 24, color: "#1b64b0"}}>
                    <TableRow>
                        <CellHead width={'40%'}>{props.category}</CellHead>
                        <CellHead width={'15%'} align="center">Basic</CellHead>
                        <CellHead width={'15%'}align="center">Business</CellHead>
                        <CellHead width={'15%'} align="center">Premium</CellHead>
                        <CellHead width={'15%'} align="center">Enterprise</CellHead>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                       props.features.map((key) => (
                           <TableRow key={key.title} style={{maxWidth: 600}}>
                               <TableCell component="th" scope="row">
                                   {key.title}
                               </TableCell>
                               <TableCell align="center">
                                   {renderItem(key.basic)}
                               </TableCell>
                               <TableCell align="center">
                                   {renderItem(key.business)}
                               </TableCell>
                               <TableCell align="center">
                                   {renderItem(key.premium)}
                               </TableCell>
                               <TableCell align="center">
                                   {renderItem(key.enterprise)}
                               </TableCell>
                           </TableRow>
                       ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
