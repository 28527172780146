/*
 * Created by Dong Nguyen.
 */

import Cookies from "universal-cookie";
import { isLocal } from "./axios";

export default new Cookies();

export function getCustomOptions() {
    let domain = window.location.hostname
        .split(".")
        .slice(-2)
        .join(".");
    // let secure = window.location.protocol !== "http:";
    let secure = !isLocal;
    return { domain, secure };
}
