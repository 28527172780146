/*
 * Created by Dong Nguyen.
 */

/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Created by Dong Nguyen.
 */

import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormContainerComponent from "../components/FormContainerComponent";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CheckCircle, Lock } from "@material-ui/icons";
import EyeComponent from "../components/EyeComponent";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import StrongPasswordComponent from "../components/StrongPasswordComponent";
import Popper from "@material-ui/core/Popper";
import { isStrongPassword } from "../providers/strongPassword";
import axios from "../providers/axios";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import VerifyPasswordComponent from "../components/VerifyPasswordComponent";
import getLanguage from "../providers/localize";

const useStyles = makeStyles(theme => {
    return {
        sample: {
            margin: theme.spacing(1),
        },
        heading: {
            marginBottom: theme.spacing(5),
        },
        root: {
            "& .MuiTextField-root": {
                marginBottom: theme.spacing(2),
            },
        },
        submitButton: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        loginButton: {
            // marginBottom: theme.spacing(2),
            // marginLeft: theme.spacing(1),
            // marginRight: theme.spacing(1),
        },
    };
});

export default function({ history }) {
    const classes = useStyles();
    const { token } = useParams();

    const [capsLockIsOn, setCapsLockIsOn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElVerify, setAnchorElVerify] = useState(null);
    const [password, setPassword] = useState(null);
    const [verifyPassword, setVerifyPassword] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorVerifyPassword, setErrorVerifyPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    let redirectLoginTo = -1;

    const _onSubmit = e => {
        e.preventDefault();
        setErrorVerifyPassword(false);
        setErrorMessage(null);
        if (password !== verifyPassword) {
            setErrorVerifyPassword(true);
            return;
        }
        if (!isStrongPassword(password)) {
            setErrorMessage("New Password is too weak!");
            return;
        }
        setLoading(true);
        axios
            .post("/auth/reset/" + token, {
                lang: getLanguage(),
                newPassword: password,
                verifyPassword,
            })
            .then(() => {
                setLoading(false);
                setPassword(null);
                setVerifyPassword(null);
                // redirectLoginTo = setTimeout(() => {
                //     history.push("/login");
                // }, 10000);
                setOpen(true);
            })
            .catch(e => {
                setLoading(false);
                try {
                    let {
                        response: { data },
                    } = e;
                    setErrorMessage(data.message);
                } catch (ignored) {}
            });
    };
    const _selectOnFocus = (e, ignore, ignore2) => {
        e.target.select();
        if (ignore !== true) {
            setAnchorEl(null);
        }
        if (ignore2 !== true) {
            setAnchorElVerify(null);
        }
    };
    const _onKeyUp = event => {
        setCapsLockIsOn(event.getModifierState("CapsLock"));
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        // Mounted.
        if (!token || token === "invalid") {
            history.push("/login");
        }
    }, []);
    useEffect(() => {
        // Mounted or updated.
    });
    useEffect(() => {
        return () => {
            // Will unmount.
        };
    }, []);

    return (
        <FormContainerComponent>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{"Password changed successfully."}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {"Your password has been updated. Please sign in with your new password."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={e => {
                            handleClose(e);
                            clearTimeout(redirectLoginTo);
                            history.push("/login");
                        }}
                        className={classes.loginButton}
                        variant="contained"
                        color="primary"
                        fullWidth={true}>
                        {"SIGN IN"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Typography variant="h4" className={classes.heading}>
                {"Change Password"}
            </Typography>
            <form className={classes.root} autoComplete="off" onSubmit={e => _onSubmit(e)}>
                <TextField
                    helperText={capsLockIsOn ? "CAPS LOCK IS ON." : null}
                    label={"New Password"}
                    fullWidth={true}
                    required={true}
                    type={showPassword ? "text" : "password"}
                    placeholder={"********"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock />
                                {isStrongPassword(password) && password === verifyPassword && (
                                    <CheckCircle
                                        style={{
                                            position: "absolute",
                                            top: 4,
                                            left: -35,
                                            color: "#28a745",
                                        }}
                                    />
                                )}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <EyeComponent onShow={() => setShowPassword(true)} onHide={() => setShowPassword(false)} />
                        ),
                    }}
                    onFocus={e => {
                        setAnchorEl(e.currentTarget);
                        _selectOnFocus(e, true);
                    }}
                    onKeyUp={_onKeyUp}
                    onBlur={() => {
                        setCapsLockIsOn(false);
                    }}
                    onChange={e => setPassword(e.target.value)}
                />
                <Popper
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    placement="right"
                    style={{
                        pointerEvents: "none",
                    }}>
                    <StrongPasswordComponent value={password} />
                </Popper>
                <TextField
                    error={errorVerifyPassword}
                    label={"Verify Password"}
                    fullWidth={true}
                    required={true}
                    type={showVerifyPassword ? "text" : "password"}
                    placeholder={"********"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock />
                                {isStrongPassword(password) && password === verifyPassword && (
                                    <CheckCircle
                                        style={{
                                            position: "absolute",
                                            top: 4,
                                            left: -35,
                                            color: "#28a745",
                                        }}
                                    />
                                )}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <EyeComponent
                                onShow={() => setShowVerifyPassword(true)}
                                onHide={() => setShowVerifyPassword(false)}
                            />
                        ),
                    }}
                    onFocus={e => {
                        setAnchorElVerify(e.currentTarget);
                        _selectOnFocus(e, false, true);
                    }}
                    onBlur={() => {
                        setAnchorElVerify(null);
                    }}
                    onChange={e => setVerifyPassword(e.target.value)}
                />
                <Popper
                    open={!!anchorElVerify}
                    anchorEl={anchorElVerify}
                    placement="right"
                    style={{
                        pointerEvents: "none",
                    }}>
                    <VerifyPasswordComponent value={password && password === verifyPassword} />
                </Popper>
                {errorMessage && (
                    <Grow in={!!errorMessage}>
                        <MuiAlert elevation={1} severity="error" className={classes.mUIAlert}>
                            {errorMessage}
                        </MuiAlert>
                    </Grow>
                )}
                <Button
                    disabled={loading}
                    className={classes.submitButton}
                    variant="contained"
                    size="large"
                    color="primary"
                    type={"submit"}
                    fullWidth={true}>
                    {"CHANGE PASSWORD"}
                    {loading ? "..." : ""}
                </Button>
            </form>
        </FormContainerComponent>
    );
}
