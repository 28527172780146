/*
 * Created by Dong Nguyen.
 */

const to_string = str => {
    return String(str || "");
};

export const checkers = [
    str => {
        return !!to_string(str).match(/.*[A-Z].*/);
    },
    str => {
        return !!to_string(str).match(/.*[a-z].*/);
    },
    str => {
        return !!to_string(str).match(/.*\d.*/);
    },
    str => {
        return !!to_string(str).match(/.*\W.*/);
    },
    str => {
        return to_string(str).length > 7;
    },
];

export const isStrongPassword = password => {
    for (let i = 0; i < checkers.length; i++) {
        if (!checkers[i](password)) return false;
    }
    return true;
};
