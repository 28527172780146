/*
 * Created by Dong Nguyen.
 */

/*
 * Created by Dong Nguyen.
 */

/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Created by Dong Nguyen.
 */

import React, { useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ListItemIcon, ListItemText, Paper } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { CheckCircle } from "@material-ui/icons";

const useStyles = makeStyles(theme => {
    return {
        sample: {
            margin: theme.spacing(1),
        },
        container: {
            marginLeft: 40,
            pointerEvents: "auto",
        },
        yes: {
            color: "rgb(83, 183, 106)",
        },
        no: {
            color: "rgb(215, 215, 215)",
        },
        fontSize: {
            fontSize: "16px",
        },
        good: {
            color: "#28a745",
        },
        bad: {
            color: "red",
        },
    };
});

export default function({ value, message }) {
    const classes = useStyles();

    useEffect(() => {
        // Mounted.
    }, []);
    useEffect(() => {
        // Mounted or updated.
    });
    useEffect(() => {
        return () => {
            // Will unmount.
        };
    }, []);

    let currentClass = classes.no;
    let statusClass = classes.bad;
    if (value) {
        currentClass = classes.yes;
        statusClass = classes.good;
    }

    return (
        <Paper elevation={2} className={classes.container}>
            <List>
                <ListItem button={false}>
                    <ListItemText
                        primary={
                            <span>
                                {"Verify Password"}:{" "}
                                <span className={statusClass}>{value ? "Matched!" : "Not Matched!"}</span>
                            </span>
                        }
                    />
                </ListItem>
                <ListItem button={true}>
                    <ListItemIcon>
                        <CheckCircle className={currentClass} />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <span className={classes.fontSize}>{message || "Must be the same new password."}</span>
                        }
                    />
                </ListItem>
            </List>
        </Paper>
    );
}
