import React from "react";
import styled from "styled-components";
import {makeStyles} from '@material-ui/core/styles';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "center"
    },
    dropDownBtn: {
        padding: "6px 8px 6px 16px",
        textDecoration: "none",
        fontSize: "14px",
        color: "#ffffff",
        display: "block",
        border: "none",
        background: "none",
        textAlign:"left",
        cursor: "pointer",
        outline: "none",
        '&:hover': {
            color: "#ffa500",
        }
    },
    dropDownContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "20px",
    }

}))

const DropDownContentItem = styled.span`
  color: white;
  text-decoration: none;
  display: block;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding-left: 12px;
  margin-bottom: 10px;
  &:hover {
    color: #ffa500;
  }
`



export default function SideBarItem (props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen(!open);
    }

    return (
        <div>
            <div className={classes.root} onClick={handleToggle}>
                <button className={classes.dropDownBtn}>
                    {props.title}
                </button>
                {props.subList ? <ArrowDropDownIcon onClick={handleToggle}/> : ""}
            </div>
            {open ?
                <div className={classes.dropDownContainer}>
                    { props.subList ?
                        props.subList.map((item) => (
                            <a href={item.href}>
                                <DropDownContentItem>
                                    {item.title}
                                </DropDownContentItem>
                            </a>
                        ))
                        : ""
                    }
                </div>
                :
                <div>

                </div>
            }
        </div>
    )
}