import React, { useEffect, useState } from "react";
import vAccAxios from "../providers/axios";
import { useGlobal } from "../actions";
// import
export function LoginError({ location, history }) {
    const [globalState, globalActions] = useGlobal();
    const [errMessage, setErrorMessage] = useState("Logged in error!");

    useEffect(() => {
        if (location.state.errorMessage) setErrorMessage(location.state.errorMessage);
    }, [location.state.errorMessage]);

    useEffect(() => {
        const logOutAndClearCookie = () => {
            vAccAxios.post("/auth/signout").then(() => globalActions.logout());
        };
        logOutAndClearCookie();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
            {errMessage}
        </div>
    );
}
