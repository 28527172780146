/*
 * Created by Dong Nguyen.
 */

/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Created by Dong Nguyen.
 */

import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormContainerComponent from "../components/FormContainerComponent";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Lock } from "@material-ui/icons";
import EyeComponent from "../components/EyeComponent";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import StrongPasswordComponent from "../components/StrongPasswordComponent";
import Popper from "@material-ui/core/Popper";
import { isStrongPassword } from "../providers/strongPassword";
import axios, { updateAuthorization } from "../providers/axios";
import { useGlobal } from "../actions";
import cookies from "../providers/cookies";
import VerifyPasswordComponent from "../components/VerifyPasswordComponent";
import getLanguage from "../providers/localize";

const useStyles = makeStyles(theme => {
    return {
        sample: {
            margin: theme.spacing(1),
        },
        heading: {
            marginBottom: theme.spacing(5),
        },
        root: {
            "& .MuiTextField-root": {
                marginBottom: theme.spacing(2),
            },
        },
        submitButton: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
    };
});

export default function() {
    const classes = useStyles();
    const [, globalActions] = useGlobal();

    const [capsLockIsOn, setCapsLockIsOn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElVerify, setAnchorElVerify] = useState(null);
    const [password, setPassword] = useState(null);
    const [currentPassword, setCurrentPassword] = useState(null);
    const [verifyPassword, setVerifyPassword] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorVerifyPassword, setErrorVerifyPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const _onSubmit = e => {
        e.preventDefault();
        setErrorVerifyPassword(false);
        setErrorMessage(null);
        if (password !== verifyPassword) {
            setErrorVerifyPassword(true);
            return;
        }
        if (!isStrongPassword(password)) {
            setErrorMessage("New Password is too weak!");
            return;
        }
        if (password === currentPassword) {
            setErrorMessage("New password is the same as current password!");
            return;
        }
        setLoading(true);
        axios
            .post("/users/password", {
                lang: getLanguage(),
                newPassword: password,
                verifyPassword,
                currentPassword,
            })
            .then(({ data }) => {
                setLoading(false);
                updateAuthorization(data);
                globalActions.setMessageData({
                    type: "success",
                    content: data.message,
                });
                globalActions.changedPassword();
                setPassword(null);
                setCurrentPassword(null);
                setVerifyPassword(null);
                let returnURL = cookies.get("return_url");
                if (returnURL) {
                    window.location.href = returnURL;
                }
            })
            .catch(e => {
                setLoading(false);
                try {
                    let {
                        response: { data },
                    } = e;
                    setErrorMessage(data.message);
                } catch (ignored) {}
            });
    };
    const _selectOnFocus = (e, ignore, ignore2) => {
        e.target.select();
        if (ignore !== true) {
            setAnchorEl(null);
        }
        if (ignore2 !== true) {
            setAnchorElVerify(null);
        }
    };
    const _onKeyUp = event => {
        setCapsLockIsOn(event.getModifierState("CapsLock"));
    };

    useEffect(() => {
        // Mounted.
    }, []);
    useEffect(() => {
        // Mounted or updated.
    });
    useEffect(() => {
        return () => {
            // Will unmount.
        };
    }, []);

    return (
        <FormContainerComponent>
            <form className={classes.root} autoComplete="off" onSubmit={e => _onSubmit(e)}>
                <TextField
                    label={"Current Password"}
                    fullWidth={true}
                    required={true}
                    type={showCurrentPassword ? "text" : "password"}
                    placeholder={"********"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock />
                            </InputAdornment>
                        ),
                        endAdornment: <EyeComponent onShow={() => setShowCurrentPassword(true)} onHide={() => setShowCurrentPassword(false)} />,
                    }}
                    onFocus={e => {
                        _selectOnFocus(e);
                    }}
                    onChange={e => setCurrentPassword(e.target.value)}
                />
                <TextField
                    helperText={capsLockIsOn ? "CAPS LOCK IS ON." : null}
                    label={"New Password"}
                    fullWidth={true}
                    required={true}
                    type={showPassword ? "text" : "password"}
                    placeholder={"********"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock />
                            </InputAdornment>
                        ),
                        endAdornment: <EyeComponent onShow={() => setShowPassword(true)} onHide={() => setShowPassword(false)} />,
                    }}
                    onFocus={e => {
                        setAnchorEl(e.currentTarget);
                        _selectOnFocus(e, true);
                    }}
                    onKeyUp={_onKeyUp}
                    onBlur={() => {
                        // setAnchorEl(null);
                        setCapsLockIsOn(false);
                    }}
                    onChange={e => setPassword(e.target.value)}
                />
                <Popper
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    placement="right"
                    style={{
                        pointerEvents: "none",
                    }}>
                    <StrongPasswordComponent value={password} />
                </Popper>
                <TextField
                    error={errorVerifyPassword}
                    label={"Verify Password"}
                    fullWidth={true}
                    required={true}
                    type={showVerifyPassword ? "text" : "password"}
                    placeholder={"********"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock />
                            </InputAdornment>
                        ),
                        endAdornment: <EyeComponent onShow={() => setShowVerifyPassword(true)} onHide={() => setShowVerifyPassword(false)} />,
                    }}
                    onFocus={e => {
                        setAnchorElVerify(e.currentTarget);
                        _selectOnFocus(e, false, true);
                    }}
                    onBlur={() => {
                        setAnchorElVerify(null);
                    }}
                    onChange={e => setVerifyPassword(e.target.value)}
                />
                <Popper
                    open={!!anchorElVerify}
                    anchorEl={anchorElVerify}
                    placement="right"
                    style={{
                        pointerEvents: "none",
                    }}>
                    <VerifyPasswordComponent value={password && password === verifyPassword} />
                </Popper>
                {errorMessage && (
                    <Grow in={!!errorMessage}>
                        <MuiAlert elevation={1} severity="error" className={classes.mUIAlert}>
                            {errorMessage}
                        </MuiAlert>
                    </Grow>
                )}
                <Button disabled={loading} className={classes.submitButton} variant="contained" size="large" color="primary" type={"submit"} fullWidth={true}>
                    {"CHANGE PASSWORD"}
                    {loading ? "..." : ""}
                </Button>
            </form>
        </FormContainerComponent>
    );
}
