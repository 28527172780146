/*
 * Created by Dong Nguyen.
 */

import * as React from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";

function EyeComponent(props) {
    const stateLists = { show: 1, hide: 0 };
    const [state, setState] = React.useState(stateLists.show);

    const _onClickedEye = () => {
        setState(state === stateLists.show ? stateLists.hide : stateLists.show);
        if (state === stateLists.show) props.onShow && props.onShow();
        else props.onHide && props.onHide();
    };

    return (
        <InputAdornment
            position="end"
            onClick={_onClickedEye}
            style={{
                cursor: "pointer",
            }}>
            {state === stateLists.show ? <VisibilityOff /> : <Visibility />}
        </InputAdornment>
    );
}

EyeComponent.propTypes = {
    onShow: PropTypes.func,
    onHide: PropTypes.func,
};

export default EyeComponent;
