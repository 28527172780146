export const Packages = [
    {value: 'Basic', label: 'Basic'},
    {value: 'Business', label: 'Business'},
    {value: 'Premium', label: 'Premium'},
]

export const PLANS = {
    BASIC: {
        name: 'Basic',
        fitWith: 'Visualize your Routing Plan',
        price: '0',
        tryButtonText: 'Try now',
        topFeatures: 'Visualize your Routing Plan. Suitable for businesses with a fleet of 1 - 3 vehicles',
    },
    BUSINESS: {
        name: 'Business',
        fitWith: 'Suitable for Internal Mid-size Fleet',
        price: '350',
        tryButtonText: 'Start free trial',
        topFeatures: 'Management, Auto Route Planning Optimization, Monitoring Driver\'s execution and Reporting. Suitable for businesses with a fleet of 4 - 10 vehicles.',
    },
    PREMIUM: {
        name: 'Premium',
        fitWith: 'Assist your Planners',
        price: '900',
        tryButtonText: 'Start free trial',
        topFeatures: 'Assist your Planners with external Carriers and Costs. Suitable for businesses with a fleet of more than 10 vehicles.',
    }
}

export const COMPARISON = [
    {
        name: "Management",
        features: [
            {
                title: "Manage Organization Chart, Branches, Depots",
                basic: true,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Manage Customers and Suppliers",
                basic: true,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Manage Products, Product Categories and Inventory",
                basic: true,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Manage Vehicle and Vehicle Types",
                basic: true,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Manage Services and Service Types",
                basic: true,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Manage Sales and Purchase Orders",
                basic: true,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Manage Transfer and Contract Orders (Coming Soon)",
                basic: false,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Manage Route Execution With Mobile App",
                basic: false,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Manage Charges",
                basic: false,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Monitoring & Control",
                basic: false,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Manage Notifications",
                basic: false,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Manage Integrations",
                basic: false,
                business: false,
                premium: true,
                enterprise: true,
            },
            {
                title: "Descriptive Reports & Dashboards",
                basic: false,
                business: false,
                premium: true,
                enterprise: true,
            },
            {
                title: "Carrier Portal",
                basic: false,
                business: false,
                premium: true,
                enterprise: true,
            },
            {
                title: "Advanced Diagnostic & Predictive Analytics",
                basic: false,
                business: false,
                premium: false,
                enterprise: true,
            },
            {
                title: "Audit Log",
                basic: false,
                business: false,
                premium: false,
                enterprise: true,
            },
            {
                title: "Single Sign-on",
                basic: false,
                business: false,
                premium: false,
                enterprise: true,
            },
            {
                title: "Two-Factor Authentication",
                basic: false,
                business: false,
                premium: false,
                enterprise: true,
            }
        ]
    },
    {
        name: "Planning",
        features: [
            {
                title: "Manual Import Planning",
                basic: true,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Manage Route Planning",
                basic: true,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Route Plan List View",
                basic: true,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Route Plan Map View",
                basic: true,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Auto Route Planning",
                basic: false,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "3D Loading Planning",
                basic: false,
                business: false,
                premium: true,
                enterprise: true,
            },
            {
                title: "Dock Schedule Planning",
                basic: false,
                business: false,
                premium: true,
                enterprise: true,
            },
            {
                title: "Group Direction Machine Learning (Coming Soon)",
                basic: false,
                business: false,
                premium: false,
                enterprise: true,
            }
        ]
    },
    {
        name: "Usage",
        features: [
            {
                title: "Number Of Branch",
                basic: 1,
                business: "Unlimited",
                premium: "Unlimited",
                enterprise: "Unlimited",
            },
            {
                title: "Number Of Depot",
                basic: 1,
                business: "Unlimited",
                premium: "Unlimited",
                enterprise: "Unlimited",
            },
            {
                title: "Number Of Route Plan Per Day",
                basic: 2,
                business: "Unlimited",
                premium: "Unlimited",
                enterprise: "Unlimited",
            },
            {
                title: "Number Of Customer Per Route Plan",
                basic: 100,
                business: 500,
                premium: 1000,
                enterprise: 2000,
            },
            {
                title: "Number Of User",
                basic: 10,
                business: 25,
                premium: 65,
                enterprise: 220,
            },
            {
                title: "Addition users fee (per user)",
                basic: false,
                business: "$14",
                premium: "$13.5",
                enterprise: "Contact us",
            }
        ]
    },
    {
        name: "Service",
        features: [
            {
                title: "Email Support",
                basic: true,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "8x5 In-App Live Chat",
                basic: false,
                business: true,
                premium: true,
                enterprise: true,
            },
            {
                title: "Admin Training Webinar",
                basic: false,
                business: false,
                premium: true,
                enterprise: true,
            },
            {
                title: "Dedicated Customer Success Manager",
                basic: false,
                business: false,
                premium: false,
                enterprise: true,
            },
            {
                title: "Onboarding Training Workshop",
                basic: false,
                business: false,
                premium: false,
                enterprise: true,
            },
            {
                title: "24x7 In-App Live Chat",
                basic: false,
                business: false,
                premium: false,
                enterprise: true,
            }
        ]
    },
    {
        name: "Customization",
        features: [
            {
                title: "Custom Dashboard (Coming Soon)",
                basic: false,
                business: false,
                premium: true,
                enterprise: true,
            },
            {
                title: "Custom Fields (Coming Soon)",
                basic: false,
                business: false,
                premium: true,
                enterprise: true,
            },
            {
                title: "Custom Modules (Coming Soon)",
                basic: false,
                business: false,
                premium: false,
                enterprise: true,
            },
            {
                title: "Custom Resources (Coming Soon)",
                basic: false,
                business: false,
                premium: false,
                enterprise: true,
            }
        ]
    }
]

export const MENU = [
    {
        title: "Product",
        href: "https://www.abivin.com/vroute",
        id: "product",
        subList: [
            {
                title: "Integration",
                href: "https://www.abivin.com/integration",
            },
            {
                title: "Public Roadmap",
                href: "https://roadmap.abivin.com/",
            }
        ]
    },
    {
        title: "Solutions",
        id: "solutions",
        href: "https://www.abivin.com/container-freight",
        subList: [
            {
                title: "Intermodal Freight Carriers",
                href: "https://www.abivin.com/container-freight",
            },
            {
                title: "Manufactures/Constructors",
                href: "https://www.abivin.com/1pls-manufacturers",
            },
            {
                title: "Inter-city Carriers/3PLs",
                href: "https://www.abivin.com/3pls-transporters",
            },
            {
                title: "Distributors",
                href: "https://www.abivin.com/distributors",
            },
            {
                title: "Retailers",
                href: "https://www.abivin.com/retailers",
            }
        ]
    },
    {
        title: "Pricing",
        id: "pricing",
        href: `${(window.location.origin || window.location.hostname)}/#/pricing`,
    },
    {
        title: "Resources",
        id: "resources",
        href: "https://www.abivin.com/resources",
        subList: [
            {
                title: "FAQs",
                href: "https://www.abivin.com/faq",
            },
            {
                title: "Blog",
                href: "https://www.abivin.com/blog",
            },
            {
                title: "Forum",
                href: "https://www.abivin.com/forum",
            },
            {
                title: "Press",
                href: "https://www.abivin.com/press",
            },
            {
                title: "Documentation",
                href: "https://docs.abivin.com/docs",
                newTab: true
            },
            {
                title: "Tutorial Videos",
                href: "https://www.youtube.com/watch?v=0YEQI8y38BU&list=PLZMWOfaNV00EGFMbPNirX8B7gYvYdy12J",
                newTab: true
            },
            {
                title: "Operational Status",
                href: "https://statuspage.freshping.io/1997-AbivinStatusPage",
                newTab: true
            },
            {
                title: "Careers",
                href: "https://abivin.freshteam.com/jobs",
                newTab: true
            }
        ]
    },
]

export const ASKED_QUESTION = [
    {
        ask: "Why should I use Abivin vRoute?",
        answer: "Abivin vRoute is a fully-fledged Transport Management System. You should use Abivin vRoute if you are facing problems in logistics like manual transportation management, inefficient delivery planning, high logistics costs, too much dependence on driver/dispatcher experience, or lack of transport visibility."
    },
    {
        ask: "How can Abivin vRoute help my company?",
        answer: "Abivin vRoute helps manage multiple logistics tasks in one comprehensive platform, making it the operating system of your supply chain. We help you decrease ~80% route/shipment planning time, enable complete transportation visibility and automatically export insightful reports.",
    },
    {
        ask: "What are the main differences between the Business, Premium, and Enterprise plans?",
        answer: "Number of users: The Business plan may have fewer users than the Premium and Enterprise plans, which allow more users to access the platform.\n" +
            "Features: The Business Plan only includes route planning, whereas the Premium and Enterprise plans include dock scheduling, 3D loading, and driver assignment.\n" +
            "Support: The Enterprise plan includes in-app live chat 24 hours a day, seven days a week.",
    },
    {
        ask: "What happens after the 7-day free trial for the Business and Premium plans?",
        answer: "Users may need to select a paid subscription plan to continue using the platform. If the user chooses not to subscribe, their access to the platform may be restricted or suspended until they select a paid subscription plan or cancel their account.",
    },
    {
        ask: "Can I switch between plans, and how easy is it to do so?",
        answer: "Yes, you can. You can contact us or follow the tutorial to upgrade your subscription",
        href: "https://docs.abivin.com/docs/user-register"
    }

]